<script setup>
import { ref } from 'vue';
import axios from "axios";
import Swal from "sweetalert2";
import router from '../../router/index'
import store from "@/state/store";
import { ls } from "@/state/secureStore";

const userName = ref('')
const userPass = ref('')
const authLoading = ref(false)

function Login(){

  authLoading.value = true

  let data = new FormData();
  data.append('username', userName.value)
  data.append('password', userPass.value)

  axios.post(process.env.VUE_APP_B2B_API_URL+'auth/login', data).then((data) => {
    if(data.data.status){
      Swal.fire({
        title: "Hoşgeldiniz!",
        html: 'Oturumunuz doğrulandı, yönlendiriliyorsunuz...',
        icon: "success",
        timer: 1500,
        confirmButtonText: "Tamam",
      });
      ls.set('jwt', data.data.jwt)
      store.dispatch(
        "userAuth/logIn",
        {
          userNo: userName.value,
          userID: data.data.user['contactNo'],
          jwt: data.data.jwt,
          user: JSON.stringify(data.data.user)
        })
      store.dispatch("user/getBelge");
      router.push('/');
    } else {
      Swal.fire({
        title: "Hata!",
        html: data.data.msg,
        icon: "error",
        confirmButtonText: "Tamam",
      });
      authLoading.value = false
    }
  }).catch((error) => {
    Swal.fire({
        title: "Hata!",
        html: "Bir hata oluştu!",
        icon: "error",
        confirmButtonText: "Tamam",
    });
    console.log(error);
    authLoading.value = false
  })
}
</script>

<template>
  <!-- auth-page wrapper -->
  <div
    class="
      auth-page-wrapper auth-bg-cover
      py-5
      d-flex
      justify-content-center
      align-items-center
      min-vh-100
    "
  >
    <div class="bg-overlay"></div>
    <!-- auth-page content -->
    <div class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card overflow-hidden">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="p-lg-5 p-4 auth-one-bg h-100">
                    <div class="bg-overlay"></div>
                    <div class="position-relative h-100 d-flex flex-column">
                      <div class="mb-4">
                        <router-link to="/" class="d-block">
                          <img
                            src="@/assets/images/logo-light.png"
                            alt=""
                            height="18"
                          />
                        </router-link>
                      </div>
                      <div class="mt-auto">
                        <div class="mb-3">
                          <i
                            class="ri-double-quotes-l display-4 text-success"
                          ></i>
                        </div>

                        <div
                          id="qoutescarouselIndicators"
                          class="carousel slide"
                          data-bs-ride="carousel"
                        >
                          <div class="carousel-indicators">
                            <button
                              type="button"
                              data-bs-target="#qoutescarouselIndicators"
                              data-bs-slide-to="0"
                              class="active"
                              aria-current="true"
                              aria-label="Slide 1"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#qoutescarouselIndicators"
                              data-bs-slide-to="1"
                              aria-label="Slide 2"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#qoutescarouselIndicators"
                              data-bs-slide-to="2"
                              aria-label="Slide 3"
                            ></button>
                          </div>
                          <div
                            class="carousel-inner text-center text-white pb-5"
                          >
                            <div class="carousel-item active">
                              <p class="fs-15 fst-italic">
                                " Size özel fiyatlar ve tekliflerle alışverişin tadını çıkarın! "
                              </p>
                            </div>
                            <div class="carousel-item">
                              <p class="fs-15 fst-italic">
                                " Özel teklif fırsatları ile alışverişe başlayın! <br />elektromarketim.com #heranındayanında "
                              </p>
                            </div>
                            <div class="carousel-item">
                              <p class="fs-15 fst-italic">
                                " Kişisel müşteri temsilcisi ve kurumsal iletişim danışmanlarımızla alışverişe başla "
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- end carousel -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->

                <div class="col-lg-6">
                  <div class="p-lg-5 p-4">
                    <div>
                      <h5 class="text-primary">Hoşgeldiniz!</h5>
                      <p class="text-muted">
                        Teklif sistemine devam etmek için oturum açın.
                      </p>
                    </div>

                    <div class="mt-4">
                        <div class="mb-3">
                          <label for="eposta" class="form-label">E-Posta</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="E-Postanızı giriniz"
                            v-model="userName"
                            @keydown.enter.prevent="Login"
                          />
                        </div>

                        <div class="mb-3">
                          <div class="float-end">
                            <router-link to="/parola-sifirla" class="text-muted"
                              >Parolanızı mı unuttunuz?</router-link
                            >
                          </div>
                          <label class="form-label" for="password-input"
                            >Parola</label
                          >
                          <div
                            class="position-relative auth-pass-inputgroup mb-3"
                          >
                            <input
                              type="password"
                              class="form-control pe-5"
                              placeholder="Parolanızı giriniz"
                              v-model="userPass"
                              @keydown.enter.prevent="Login"
                            />
                            <button
                              class="
                                btn btn-link
                                position-absolute
                                end-0
                                top-0
                                text-decoration-none text-muted
                              "
                              type="button"
                              id="password-addon"
                            >
                              <i class="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <label
                            class="form-check-label"
                            for="auth-remember-check"
                            >Beni hatırla</label
                          >
                        </div>

                        <div class="mt-4">
                          <button class="btn btn-success w-100" @click="Login" :disabled="authLoading">
                            <span v-if="authLoading">Oturum açılıyor...</span>
                            <span v-else>Oturum Aç</span>
                          </button>
                        </div>
                    </div>

                    <div class="mt-5 text-center">
                      <p class="mb-0">
                        Hesabınız yok mu ?
                        <router-link
                          to="/hesap-olustur"
                          class="fw-bold text-primary text-decoration-underline"
                        >
                          Hesap oluşturun</router-link
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0">
                &copy; {{ new Date().getFullYear() }} Elektromarketim.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>